import { ChevronRightSVG } from '@sbercloud/uikit-product-icons';
import { ButtonFunction } from '@snack-uikit/button';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './AdditionalLinks.module.scss';

export function AdditionalLinks({ className = '' }: { className?: string }) {
  const { clickAnalytics } = useAnalytics();
  const router = useRouter();

  return (
    <div className={cn(s.additionalMenuLinks, className)}>
      <ButtonFunction
        label="Все продукты"
        href="/products"
        onClick={(e) => {
          e.preventDefault();

          clickAnalytics({
            action: 'click',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: 'Все продукты',
            uniqueId: 'additional-links-products',
            transitionType: 'inside-link',
          });

          router.push('/products');
        }}
        size="m"
        appearance="neutral"
        icon={<ChevronRightSVG />}
        iconPosition="after"
        className={className}
      />
      <ButtonFunction
        label="Marketplace"
        href="/marketplace"
        onClick={(e) => {
          e.preventDefault();

          clickAnalytics({
            action: '/marketplace',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: 'Marketplace',
            uniqueId: 'additional-links-marketplace',
            transitionType: 'inside-link',
          });

          router.push('/marketplace');
        }}
        size="m"
        appearance="neutral"
        icon={<ChevronRightSVG />}
        iconPosition="after"
      />
    </div>
  );
}
